import { subject } from "@casl/ability";
import { Subjects } from "@shared/auth/src";
import { FC, useContext, useState } from "react";
import { Column } from "react-table";
import { useDeleteLayerMutation, useGetAllLayersQuery } from "src/api/LayerApi";
import { Action } from "src/api/Permissions";
import { TerrainLayer, TerrainLayerWithBuildStatus } from "src/api/generated.api";
import { Can } from "src/casl/Can";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { ViewOrEditLinkButton } from "src/components/view-or-edit-link-button";
import { teamContext } from "../teams/context/team-context-provider";
import TableWithPagination from "src/components/tables/TableWithPagination";
import moment from "moment";
import { useManagedLayerControllerGetOneQuery } from "src/api/ManagedLayerApi";
import { Badge } from "react-bootstrap";
import { LayerStatusColors } from "./managed-layer-status-colors";
import { SimpleJobStatus } from "../job/JobStatus";

export const TerrainLayersTableView: FC<unknown> = () => {
    const { currentTeam } = useContext(teamContext);
    const [filter, setFilter] = useState("");
    const [sort, setSort] = useState("");

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(0);

    const layerQueryProps = {
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort,
        teamId: currentTeam ? currentTeam.id : ""
    }

    const { data, isFetching } = useGetAllLayersQuery(layerQueryProps,
        { refetchOnMountOrArgChange: true });

    const managedLayerGetOneQuery = useManagedLayerControllerGetOneQuery;

    const [deleteLayer] = useDeleteLayerMutation();

    const maxNameLength = 40;

    const columns: Column<TerrainLayer>[] = [

        {
            Header: "Name",
            width: undefined,
            Cell: ({ row }: { row: { original: TerrainLayer } }) => (
                <>{row.original.name.length > maxNameLength ? row.original.name.slice(0, maxNameLength) + '...' : row.original.name}</>
            ),
        },
        {
            Header: "Status",
            width: undefined,
            Cell: ({ row }: { row: { original: TerrainLayerWithBuildStatus } }) => {
                const status: string = row.original.status ? row.original.status : ''
                const statusColor: string = row.original.status ? row.original.status === "dirty" ? "danger" : "success" : "danger"
                return (status ? <Badge variant={statusColor}> {
                    status === "dirty"
                        ? "Dirty"
                        : "Up to date"
                }</Badge > : <></>);
            },
        },
        {
            Header: "Type",
            width: 100,
            Cell: ({ row }: { row: { original: TerrainLayer } }) => (
                <>{row.original.managementMode}</>
            ),
        },

        {
            Header: "Visibility",
            width: 150,
            accessor: "visibility"
        },
        {
            Header: "Created at",
            width: 180,
            Cell: ({ row }: { row: { original: TerrainLayer } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 130,
            Cell: ({ row }: { row: { original: TerrainLayer } }) => (
                <>
                    <ViewOrEditLinkButton
                        editLink={`/terrain-layers/${row.original.id}`}
                        readLink={`/terrain-layers/${row.original.id}`}
                        obj={row.original}
                        subjectType={Subjects.TerrainLayer}
                    />
                    <Can
                        I={Action.Delete}
                        this={subject(Subjects.TerrainLayer, {
                            ...row.original,
                        })}
                    >
                        <DeleteButtonWithConfirm
                            variant="secondary"
                            className="ml-1"
                            onClick={() =>
                                deleteLayer({ layerId: row.original.id })
                            }
                        />
                    </Can>
                </>
            ),
        },
    ];

    return (
        <div>
            <TableWithPagination
                columns={columns}
                data={data?.results || []}
                fetchData={(
                    pageIndexToFetch: number,
                    pageSizeToFetch: number,
                    filterToFetch: string,
                    sortToFetch: string
                ) => {
                    setPageIndex(pageIndexToFetch);
                    setPageSize(pageSizeToFetch);
                    setFilter(filterToFetch);
                    setSort(sortToFetch);
                }}
                loading={isFetching}
                itemCount={data?.total || 0}
                enableFiltering={true}
                defaultSort="createdAt:desc"
                sortableProps={[
                    { name: 'Name', accessor: 'name' },
                    { name: 'Visibility', accessor: 'visibility' },
                    { name: 'Type', accessor: 'managementMode' },
                    { name: 'Created at', accessor: 'createdAt' }
                ]}
            />
        </div>
    );
};
